import { Cancel } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import WarningIcon from '@mui/icons-material/Warning';
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Snackbar,
	Tooltip,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	IActionModalSubmitData,
	IUpdatedPatientActionData,
	TDelayRemark,
} from '.';
import { Button } from '../components/button';
import {
	severityColorPalette,
	timestampToDaytime,
	titleCase,
} from '../helpers';
import { DOCTOR_STATUS } from '../helpers/constants';
import CloseConfirmationModal from './closeConfirmationModal';
import {
	IEscalatedAppointment,
	IEscalationData,
	TEscalationAction,
	TPatientAction,
} from './fetchEscalations';
import { fetchPatientCurrentStatus } from './fetchPatientStatus';

export type TActionMode = 'add' | 'view' | 'replace';

type TPatientStatus =
	| 'done'
	| 'consulting'
	| 'checked-out'
	| 'checked-in'
	| 'booked'
	| 'cancelled'
	| 'no-show'
	| 'pending';

interface IActionModalProps {
	open: boolean;
	mode: TActionMode;
	doctorName: string;
	defaultDelayedTime: number | null;
	defaultDoctorRemarks: TDelayRemark | null;
	avgWaitTime: number;
	defaultDoctorStatus: TEscalationAction | null;
	escalatedAppointmentData: IEscalationData | null;
	onClose: () => void;
	handleSubmitButton: (d: IActionModalSubmitData) => void;
}

interface IActionModalHeader {
	mode: TActionMode;
	doctorName: string;
	avgWaitTime: number;
	arePatientsUpdated: boolean;
	defaultDoctorStatus: TEscalationAction | null;
	selectedStatus: { label: string; value: TEscalationAction } | null;
	delayRemark: TDelayRemark | null;
	delayedTime?: number | null;
	handleClose: () => void;
	handleOpenWarningModal: () => void;
}

const ActionsBasedOnDoctorStatus: Record<
	TEscalationAction | 'NA',
	{
		heading: string;
		POSITIVE: string;
		NEGATIVE: string;
	}
> = {
	DOCTOR_DELAY: {
		heading: 'Action Taken',
		POSITIVE: 'Informed',
		NEGATIVE: 'Not Required',
	},
	DOCTOR_LEAVE: {
		heading: 'Appointment Updated',
		POSITIVE: 'Yes',
		NEGATIVE: 'Not yet',
	},
	MANUAL_PRESCRIPTION: {
		heading: 'Consultation Status',
		POSITIVE: 'Done',
		NEGATIVE: 'Pending',
	},
	DOCTOR_NOT_REACHABLE: {
		heading: 'Action Taken',
		POSITIVE: 'Informed',
		NEGATIVE: 'Not Required',
	},
	NA: {
		heading: 'Action Taken',
		POSITIVE: '-',
		NEGATIVE: '-',
	},
};

const WARNING_TEXT = {
	DOCTOR_DELAY:
		"Please inform patients as per doctor's instructions to avoid further escalation.",
	DOCTOR_LEAVE:
		'Please mark doctor on leave and ensure that all the appointments are cancelled or rescheduled for the day within next 30 mins to prevent further escalation.',
	MANUAL_PRESCRIPTION:
		'Please manually check-out the patients for whom consultation has been done to avoid further escalation.',
	DOCTOR_NOT_REACHABLE: '',
};

const DELAY_REMARK_OPTIONS: Record<TDelayRemark, string> = {
	SURGERY: 'Surgery Emergency',
	AWAY: 'Doctor Away',
	DELAYED_START: 'Delayed Start',
	HEAVY_RUSH: 'Heavy Rush',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
			background: '#31363F',
		},
	},
};

const TableHeadingColumn = ({
	className,
	children,
}: {
	className?: string;
	children: React.ReactNode;
}) => {
	return (
		<th
			scope='col'
			className={`px-6 py-3 text-xs text-center font-bold text-gray-300 uppercase tracking-wider ${className}`}
		>
			{children}
		</th>
	);
};

const DataColumn = ({
	className,
	children,
	colSpan = 1,
}: {
	className?: string;
	children: React.ReactNode;
	colSpan?: number;
}) => {
	return (
		<td
			className={`px-6 py-1.5 whitespace-nowrap text-sm text-center ${className}`}
			colSpan={1}
		>
			{children}
		</td>
	);
};

const ModalHeader = ({
	mode,
	doctorName,
	avgWaitTime,
	defaultDoctorStatus,
	selectedStatus,
	delayRemark,
	delayedTime,
	arePatientsUpdated,
	handleClose,
	handleOpenWarningModal,
}: IActionModalHeader) => (
	<DialogTitle className='flex justify-between items-center  bg-[#111827] text-white'>
		<div className='flex gap-20'>
			<span className='text-xl font-semibold'>{titleCase(doctorName)}</span>

			<Tooltip key='spill' title='Wait Time = Current Time - Appt Time' arrow>
				<div className='flex flex-col justify-center gap-1 items-center text-xs'>
					<span className='text-center'>Avg Post-Appt Wait Time (in mins)</span>
					<span className='font-bold text-sm'>{avgWaitTime}</span>
				</div>
			</Tooltip>

			{defaultDoctorStatus !== null && defaultDoctorStatus !== undefined && (
				<div className='flex flex-col justify-center items-center text-xs'>
					<p>Status</p>
					<p className='font-bold text-sm'>
						{DOCTOR_STATUS.find((d) => d.value === defaultDoctorStatus)?.label}
					</p>
				</div>
			)}

			{selectedStatus?.value === 'DOCTOR_DELAY' && delayedTime !== null && (
				<div className='flex flex-col justify-center items-center text-xs'>
					<p>Doctor Delayed Time</p>
					<p className='font-bold text-sm'>{delayedTime + ' mins'}</p>
				</div>
			)}

			{selectedStatus?.value === 'DOCTOR_DELAY' &&
				delayedTime !== null &&
				delayRemark !== null && (
					<div className='flex flex-col justify-center items-center text-xs'>
						<p>Doctor Delay Remark</p>
						<p className='font-bold text-sm'>
							{DELAY_REMARK_OPTIONS[delayRemark]}
						</p>
					</div>
				)}
		</div>

		<div>
			<IconButton
				onClick={
					mode !== 'view' && arePatientsUpdated
						? handleOpenWarningModal
						: handleClose
				}
				size='small'
				style={{ color: 'white' }}
			>
				<Cancel />
			</IconButton>
		</div>
	</DialogTitle>
);

const ActionModal = ({
	open,
	mode = 'add',
	onClose,
	doctorName,
	avgWaitTime,
	escalatedAppointmentData: data,
	defaultDoctorStatus,
	defaultDelayedTime,
	defaultDoctorRemarks,
	handleSubmitButton,
}: IActionModalProps) => {
	const [selectedStatus, setSelectedStatus] = useState<{
		label: string;
		value: TEscalationAction;
	} | null>(DOCTOR_STATUS.find((d) => d.value === defaultDoctorStatus) ?? null);

	const [patientStatusArray, setPatientStatusArray] = useState<
		Array<Record<string, TPatientStatus>>
	>([]);

	const [openFetchedSnackbar, setOpenFetchedSnackbar] = useState(false);

	console.log({ patientStatusArray });

	const [actionMode, setMode] = useState<TActionMode>(mode);

	const [delayedTime, setDelayedTime] = useState<number | null>(null);

	const [updatedPatientActionData, setUpdatedPatientActionData] = useState<
		IUpdatedPatientActionData[]
	>([]);

	const [openWarningModal, setOpenWarningModal] = useState(false);

	const [delayRemark, setDelayRemark] = useState<TDelayRemark | null>(null);

	const appointmentData = useMemo(() => {
		if (actionMode === 'replace') {
			return {
				...data,
				escalations: data?.escalations.map((e) => ({
					...e,
					patient_action_taken: null,
				})),
			};
		}
		return data;
	}, [data, actionMode]);

	const checkedInPatients: IEscalatedAppointment[] = useMemo(() => {
		if (appointmentData) {
			return (
				appointmentData?.escalations?.filter((d) => {
					return d.patient_checkedin_time !== null;
				}) ?? []
			);
		} else return [];
	}, [appointmentData]);

	const yetToArrivePatients = useMemo(() => {
		if (appointmentData) {
			return appointmentData?.escalations?.filter((d) => {
				return d.patient_checkedin_time === null;
			});
		}
		return [];
	}, [appointmentData]);

	const isSubmitDisabled = useMemo(() => {
		return (
			(selectedStatus?.value !== 'DOCTOR_NOT_REACHABLE' &&
				updatedPatientActionData.length < 1) ||
			selectedStatus === null ||
			(selectedStatus?.value === 'DOCTOR_DELAY' &&
				(delayedTime === null || delayRemark === null))
		);
	}, [
		delayRemark,
		delayedTime,
		selectedStatus,
		updatedPatientActionData.length,
	]);

	// we need to get appointment id from here for the latest value
	const handlePatientActionUpdate = useCallback(
		(action: TPatientAction, appointmentId: string) => {
			setUpdatedPatientActionData((prev) => {
				if (prev.find((d) => d.appointment_id === appointmentId)) {
					// update existing appointment object with updated action
					return prev.map((d) => {
						if (d.appointment_id === appointmentId) {
							return {
								...d,
								patient_action_taken: action,
							};
						}
						return d;
					});
				}

				return [
					...prev,
					{
						appointment_id: appointmentId ?? '',
						patient_action_taken: action,
					},
				] as IUpdatedPatientActionData[];
			});
		},
		[]
	);

	const handleClose = useCallback(() => {
		onClose();
		setUpdatedPatientActionData([]);
		setSelectedStatus(null);
		setDelayRemark(null);
		setDelayedTime(null);
		setMode('add');
	}, [onClose]);

	const handleOpenCloseWarningModal = useCallback(() => {
		setOpenWarningModal(true);
	}, []);

	const handleSubmit = useCallback(() => {
		const patientDataToBeSubmitted: IUpdatedPatientActionData[] =
			actionMode === 'replace'
				? appointmentData?.escalations?.map((e) => {
						return {
							appointment_id: String(e.appointment_id),
							patient_action_taken:
								selectedStatus?.value === 'DOCTOR_NOT_REACHABLE'
									? null
									: updatedPatientActionData.find(
											(d) => d.appointment_id === e.appointment_id
									  )?.patient_action_taken ?? null,
						};
				  }) ?? []
				: updatedPatientActionData;

		const response: IActionModalSubmitData = {
			doctorStatus: selectedStatus?.value ?? 'DOCTOR_DELAY',
			updatedPatientData:
				selectedStatus?.value !== 'DOCTOR_NOT_REACHABLE'
					? patientDataToBeSubmitted
					: [],
			delayRemark: delayRemark ?? null,
			delayedTime:
				selectedStatus?.value === 'DOCTOR_DELAY' ? delayedTime : null,
		};

		handleSubmitButton(response);
		handleClose();
	}, [
		appointmentData?.escalations,
		delayRemark,
		delayedTime,
		handleClose,
		handleSubmitButton,
		actionMode,
		selectedStatus?.value,
		updatedPatientActionData,
	]);

	const handleFetchPatientStatus = useCallback(async (id: string) => {
		const data = await fetchPatientCurrentStatus(id);

		setPatientStatusArray((prev) => {
			const arr = prev.filter((a) => Object.keys(a)[0] !== id);

			return [...arr, { [id]: data.latest_status as TPatientStatus }];
		});

		setOpenFetchedSnackbar(true);
	}, []);

	const handleStatusChange = (event: SelectChangeEvent<TEscalationAction>) => {
		const {
			target: { value },
		} = event;

		setSelectedStatus(DOCTOR_STATUS.find((d) => d.value === value) ?? null);
	};

	useEffect(() => {
		setMode(mode);
		setSelectedStatus(
			DOCTOR_STATUS.find((d) => d.value === defaultDoctorStatus) ?? null
		);

		setDelayedTime(defaultDelayedTime);
		setDelayRemark(defaultDoctorRemarks);
	}, [defaultDelayedTime, defaultDoctorRemarks, defaultDoctorStatus, mode]);

	useEffect(() => {
		if (
			selectedStatus?.value !== undefined &&
			selectedStatus?.value !== null &&
			defaultDoctorStatus != null &&
			defaultDoctorStatus !== undefined
		) {
			if (selectedStatus?.value !== defaultDoctorStatus) {
				setMode('replace');
				setUpdatedPatientActionData([]);
			}
		}
	}, [defaultDoctorStatus, selectedStatus?.value]);

	return (
		<Dialog
			open={open}
			onClose={
				actionMode !== 'view' && updatedPatientActionData.length > 0
					? handleOpenCloseWarningModal
					: handleClose
			}
			maxWidth='xl'
			className='rounded-lg'
			fullWidth
			PaperProps={{
				style: {
					backgroundColor: 'black',
					borderRadius: '8px',
					color: 'white',
					boxShadow: '0 0 10px rgba(255, 255, 255, 0.1)',
					filter: 'none', // Ensure the popup is not blurred
					maxHeight: '600px',
				},
			}}
		>
			<CloseConfirmationModal
				open={openWarningModal}
				handleCancel={() => setOpenWarningModal(false)}
				handleCloseWithoutSaving={() => {
					handleClose();
					setOpenWarningModal(false);
				}}
				handleSaveAndClose={() => {
					handleSubmit();
					setOpenWarningModal(false);
				}}
			/>

			<ModalHeader
				mode={actionMode}
				doctorName={doctorName}
				avgWaitTime={avgWaitTime}
				defaultDoctorStatus={defaultDoctorStatus}
				selectedStatus={selectedStatus}
				delayedTime={delayedTime}
				delayRemark={delayRemark}
				arePatientsUpdated={
					!isSubmitDisabled && updatedPatientActionData.length > 0
				}
				handleClose={handleClose}
				handleOpenWarningModal={handleOpenCloseWarningModal}
			/>

			<DialogContent className='flex items-center justify-center bg-[#12141e] flex-col !px-5 !py-4 gap-3 !overflow-hidden '>
				<Snackbar
					open={openFetchedSnackbar}
					autoHideDuration={2000}
					onClose={() => {
						setOpenFetchedSnackbar(false);
					}}
					message="Patient's current status fetched!"
				/>
				{actionMode !== 'view' && defaultDoctorStatus === 'DOCTOR_LEAVE' && (
					<div className='flex justify-center items-center gap-2 text-sm p-2 px-4 my-1 border-2 border-white rounded-md bg-slate-600 bg-opacity-20'>
						<p className='text-base'>Doctor Status: </p>
						<p className='font-bold text-base'>On Leave</p>
					</div>
				)}

				{actionMode !== 'view' && defaultDoctorStatus !== 'DOCTOR_LEAVE' && (
					<div className='flex flex-row gap-2'>
						<FormControl sx={{ m: 1, width: 400 }}>
							<InputLabel
								id='select-doctor-status-label'
								className='!text-white'
							>
								Select Doctor Status
							</InputLabel>

							<Select
								labelId='select-doctor-status-label'
								id='demo-multiple-chip'
								required
								value={
									selectedStatus?.value ?? defaultDoctorStatus ?? undefined
								}
								onChange={handleStatusChange}
								input={
									<OutlinedInput
										id='select-multiple-chip'
										label='Select Doctor Status'
									/>
								}
								style={{
									color: 'white',
									borderColor: 'white',
								}}
								className='!border-white [&>fieldset]:!border-white [&>svg]:!text-white'
								renderValue={(selected) => (
									<Box
										sx={{
											display: 'flex',
											flexWrap: 'wrap',
											gap: 0.5,
										}}
									>
										{selectedStatus?.label ??
											DOCTOR_STATUS.find((d) => d.value === defaultDoctorStatus)
												?.label}
									</Box>
								)}
								MenuProps={MenuProps}
							>
								{DOCTOR_STATUS.map((doctorStat) => (
									<MenuItem
										style={{
											color: 'white',
											background: '#31363F',
										}}
										key={doctorStat.value}
										value={doctorStat.value}
									>
										{doctorStat.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						{((selectedStatus === null &&
							defaultDoctorStatus === 'DOCTOR_DELAY') ||
							selectedStatus?.value === 'DOCTOR_DELAY') && (
							<>
								<FormControl sx={{ m: 1, width: 400 }}>
									<InputLabel
										id='select-doctor-delay-time-label'
										className='!text-white'
									>
										Time Delayed (in mins)
									</InputLabel>

									<Select
										labelId='select-doctor-status-label'
										id='demo-multiple-chip'
										required={selectedStatus?.value === 'DOCTOR_DELAY'}
										defaultValue={defaultDelayedTime ?? undefined}
										value={delayedTime ?? undefined}
										onChange={(e) => {
											setDelayedTime(parseInt(String(e.target.value)));
										}}
										className='!border-white [&>fieldset]:!border-white  [&>svg]:!text-white'
										input={
											<OutlinedInput
												id='select-multiple-chip'
												label='Time Delayed (in mins)'
											/>
										}
										style={{
											color: 'white',
										}}
										renderValue={(selected) => (
											<Box
												sx={{
													display: 'flex',
													flexWrap: 'wrap',
													gap: 0.5,
												}}
											>
												{selected + ' mins'}
											</Box>
										)}
										MenuProps={MenuProps}
									>
										{Array.from(
											{ length: 24 },
											(_, index) => (index + 1) * 10
										).map((time) => (
											<MenuItem
												style={{
													color: 'white',
													background: '#31363F',
												}}
												key={time}
												value={time}
											>
												{time + ' mins'}
											</MenuItem>
										))}
									</Select>
								</FormControl>

								<FormControl sx={{ m: 1, width: 400 }}>
									<InputLabel
										id='select-doctor-delay-remark-label'
										className='!text-white'
									>
										Delay Remarks
									</InputLabel>

									<Select
										labelId='select-doctor-remark-label'
										id='delay-remark'
										required={selectedStatus?.value === 'DOCTOR_DELAY'}
										defaultValue={defaultDoctorRemarks ?? undefined}
										value={delayRemark ?? undefined}
										onChange={(e) => {
											setDelayRemark(e.target.value as TDelayRemark);
										}}
										className='!border-white [&>fieldset]:!border-white  [&>svg]:!text-white'
										input={
											<OutlinedInput
												id='select-multiple-chip'
												label='Delay Remarks'
											/>
										}
										style={{
											color: 'white',
										}}
										renderValue={(selected: TDelayRemark) => (
											<Box
												sx={{
													display: 'flex',
													flexWrap: 'wrap',
													gap: 0.5,
												}}
											>
												{DELAY_REMARK_OPTIONS[selected]}
											</Box>
										)}
										MenuProps={MenuProps}
									>
										{Object.keys(DELAY_REMARK_OPTIONS).map((opt) => {
											return (
												<MenuItem
													style={{
														color: 'white',
														background: '#31363F',
													}}
													key={opt}
													value={opt}
												>
													{DELAY_REMARK_OPTIONS[opt as TDelayRemark]}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</>
						)}
					</div>
				)}

				{actionMode !== 'view' &&
					selectedStatus?.value !== 'DOCTOR_NOT_REACHABLE' &&
					selectedStatus !== null && (
						<div className='flex justify-center items-center'>
							<WarningIcon color='warning' />
							<span className='inline-block ml-2 text-sm'>
								{WARNING_TEXT[selectedStatus?.value]}
							</span>
						</div>
					)}

				<div className='h-full overflow-y-scroll w-full'>
					<table className='w-full min-w-full divide-y divide-gray-500 rounded-lg'>
						<thead className='bg-gray-800 rounded-t-lg '>
							<tr className='rounded-t-lg'>
								<TableHeadingColumn className='rounded-tl-lg  !text-left'>
									Patient List
								</TableHeadingColumn>

								<TableHeadingColumn> Level</TableHeadingColumn>

								<TableHeadingColumn> Wait Time(in mins)</TableHeadingColumn>

								<TableHeadingColumn>Check-in Time</TableHeadingColumn>

								<TableHeadingColumn> Appointment Time</TableHeadingColumn>

								<TableHeadingColumn
									className={`font-bold ${
										actionMode === 'view' && 'rounded-tr-lg'
									}`}
								>
									Contact
								</TableHeadingColumn>

								{actionMode !== 'view' && (
									<TableHeadingColumn>Current Status </TableHeadingColumn>
								)}

								{actionMode !== 'view' && (
									<TableHeadingColumn className='font-bold rounded-tr-lg'>
										{
											ActionsBasedOnDoctorStatus[selectedStatus?.value ?? 'NA']
												.heading
										}
									</TableHeadingColumn>
								)}
							</tr>
						</thead>

						<tbody className='bg-gray-700 divide-y divide-gray-500 rounded-b-lg overflow-y-scroll'>
							{checkedInPatients.map((data: IEscalatedAppointment, i) => {
								const actionData =
									updatedPatientActionData.find(
										(d) => data.appointment_id === d.appointment_id
									)?.patient_action_taken ?? data.patient_action_taken;

								const currentPatientStatus =
									patientStatusArray.find(
										(a) => Object.keys(a)[0] === data.appointment_id
									) ?? null;

								return (
									<tr key={i} className='bg-gray-800 hover:bg-gray-700 group'>
										<DataColumn className='!text-left '>
											{titleCase(data.patient_name)}
										</DataColumn>

										<DataColumn>
											<div
												className={`bg-opacity-50 border-2 text-center rounded-full p-1 font-semibold shadow-md ${
													severityColorPalette[data.escalation_type]
												}`}
											>
												{data.escalation_type === 'NO_ESCALATION'
													? 'NE'
													: data.escalation_type}
											</div>
										</DataColumn>

										<DataColumn> {data.wait_time}</DataColumn>

										<DataColumn>
											{data.patient_checkedin_time !== null
												? timestampToDaytime(data.patient_checkedin_time)
												: 'NA'}
										</DataColumn>

										<DataColumn>
											{timestampToDaytime(data.start_time)}
										</DataColumn>

										<DataColumn>{data.mobile}</DataColumn>

										{actionMode !== 'view' &&
											(currentPatientStatus !== null ? (
												<DataColumn colSpan={1}>
													<div
														className='border border-white rounded-md flex gap-1 py-1.5 items-center justify-center bg-gray-300 bg-opacity-15 cursor-pointer hover:bg-slate-900 hover:bg-opacity-5 hover:font-semibold'
														onClick={() =>
															handleFetchPatientStatus(data.appointment_id)
														}
													>
														<span className='inline-block'>
															{titleCase(
																currentPatientStatus[data.appointment_id]
															)}
														</span>

														<RefreshIcon
															fontSize='small'
															className='inline-block'
														/>
													</div>
												</DataColumn>
											) : (
												<DataColumn colSpan={1}>
													<Button
														buttonType='blue'
														onClick={() =>
															handleFetchPatientStatus(data.appointment_id)
														}
													>
														Fetch Status
													</Button>
												</DataColumn>
											))}

										{actionMode !== 'view' &&
											(selectedStatus?.value !== null &&
											selectedStatus?.value !== undefined &&
											selectedStatus?.value !== 'DOCTOR_NOT_REACHABLE' ? (
												<DataColumn colSpan={1} className='flex gap-2'>
													<Button
														buttonType={
															actionData === 'POSITIVE' ? 'green' : 'tag'
														}
														onClick={() =>
															handlePatientActionUpdate(
																'POSITIVE',
																data?.appointment_id
															)
														}
														disabled={
															currentPatientStatus !== null &&
															currentPatientStatus[data.appointment_id] !==
																'checked-in' &&
															currentPatientStatus[data.appointment_id] !==
																'booked'
														}
													>
														{
															ActionsBasedOnDoctorStatus[selectedStatus?.value]
																.POSITIVE
														}
													</Button>

													<Button
														buttonType={
															actionData === 'NEGATIVE' ? 'selectedTag' : 'tag'
														}
														onClick={() =>
															handlePatientActionUpdate(
																'NEGATIVE',
																data?.appointment_id
															)
														}
														disabled={
															data.patient_action_taken === 'POSITIVE' ||
															(currentPatientStatus !== null &&
																currentPatientStatus[data.appointment_id] !==
																	'checked-in' &&
																currentPatientStatus[data.appointment_id] !==
																	'booked')
														}
													>
														{
															ActionsBasedOnDoctorStatus[selectedStatus?.value]
																.NEGATIVE
														}
													</Button>
												</DataColumn>
											) : (
												<DataColumn colSpan={2}>-</DataColumn>
											))}
									</tr>
								);
							})}

							{yetToArrivePatients?.length !== 0 && (
								<>
									<tr className='bg-[#12141e] border-none'>
										<td
											colSpan={8}
											className='pt-4 pb-2 whitespace-nowrap text-base text-center font-semibold text-slate-300'
										>
											Yet to arrive Patients
										</td>
									</tr>

									<tr className='rounded-t-lg bg-gray-800 !border-t-0'>
										<TableHeadingColumn className='rounded-tl-lg !text-left'>
											Patient List
										</TableHeadingColumn>

										<TableHeadingColumn>Level</TableHeadingColumn>

										<TableHeadingColumn> Wait Time(in mins)</TableHeadingColumn>

										<TableHeadingColumn>Check-in Time</TableHeadingColumn>

										<TableHeadingColumn> Appointment Time</TableHeadingColumn>

										<TableHeadingColumn
											className={`font-bold  ${
												actionMode === 'view' && 'rounded-tr-lg'
											}`}
										>
											Contact
										</TableHeadingColumn>

										{actionMode !== 'view' && (
											<TableHeadingColumn>Current Status </TableHeadingColumn>
										)}

										{actionMode !== 'view' && (
											<TableHeadingColumn className='font-bold rounded-tr-lg'>
												{
													ActionsBasedOnDoctorStatus[
														selectedStatus?.value ?? 'NA'
													].heading
												}
											</TableHeadingColumn>
										)}
									</tr>
								</>
							)}

							{yetToArrivePatients?.map((data, i) => {
								const actionData =
									updatedPatientActionData.find(
										(d) => data.appointment_id === d.appointment_id
									)?.patient_action_taken ?? data.patient_action_taken;

								return (
									<tr
										key={i}
										className='bg-gray-800 hover:bg-gray-700 border-t-0'
									>
										<td className='px-6 py-1.5 whitespace-nowrap text-sm'>
											{titleCase(data.patient_name)}
										</td>

										<td
											className={`px-6 py-1.5 whitespace-nowrap text-sm `}
											colSpan={1}
										>
											<div
												className={` text-center rounded-full p-1 font-semibold shadow-md bg-opacity-50 border-2 ${
													severityColorPalette[data.escalation_type]
												}`}
											>
												{data.escalation_type === 'NO_ESCALATION'
													? 'NE'
													: data.escalation_type}
											</div>
										</td>

										<td className='px-6 py-1.5 whitespace-nowrap text-center text-sm'>
											Yet to Arrive
										</td>

										<td className='px-6 py-1.5 whitespace-nowrap text-center text-sm'>
											NA
										</td>

										<td className='px-6 py-1.5 whitespace-nowrap text-center text-sm'>
											{timestampToDaytime(data.start_time)}
										</td>

										<td className='px-6 py-1.5 whitespace-nowrap text-center text-sm'>
											{data.mobile}
										</td>

										{actionMode !== 'view' && (
											<DataColumn colSpan={1}>-</DataColumn>
										)}

										{actionMode !== 'view' &&
											(selectedStatus?.value !== null &&
											selectedStatus?.value !== undefined &&
											selectedStatus?.value !== 'DOCTOR_NOT_REACHABLE' ? (
												<td
													className='px-6 py-1.5 flex gap-2 whitespace-nowrap text-sm'
													colSpan={2}
												>
													<Button
														buttonType={
															actionData === 'POSITIVE' ? 'green' : 'tag'
														}
														onClick={() =>
															handlePatientActionUpdate(
																'POSITIVE',
																data?.appointment_id
															)
														}
													>
														{
															ActionsBasedOnDoctorStatus[selectedStatus?.value]
																.POSITIVE
														}
													</Button>

													<Button
														buttonType={
															actionData === 'NEGATIVE' ? 'selectedTag' : 'tag'
														}
														onClick={() =>
															handlePatientActionUpdate(
																'NEGATIVE',
																data?.appointment_id
															)
														}
													>
														{
															ActionsBasedOnDoctorStatus[selectedStatus?.value]
																.NEGATIVE
														}
													</Button>
												</td>
											) : (
												<DataColumn colSpan={2}>-</DataColumn>
											))}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>

				{actionMode !== 'view' &&
					!appointmentData?.escalations?.every(
						(esc) => esc.patient_action_taken === 'POSITIVE'
					) && (
						<Button
							onClick={handleSubmit}
							buttonType='blue'
							className='!px-5 !py-2 mt-2'
							disabled={isSubmitDisabled}
						>
							Submit
						</Button>
					)}
			</DialogContent>
		</Dialog>
	);
};

export { ActionModal };
