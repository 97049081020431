import { IReportData } from "../opd/fetchReport";
import { IEscalationData } from "./fetchEscalations";

// dwarka and shalimarbagh
export const enabledHospitalsForEscalations = [
  "05478906-a974-4f78-84a2-38ce85c39206",
  "62b7ae4f-fed7-43e9-bbfa-39eb8a749e8a",
];

export const APPOINTMENT_DATA = {
  latest_status: "done",
};

export const ESCALATION_DATA: IEscalationData[] = [
  {
    doctor_name: "dr. nandita grover",
    doctor_id: "8e6b8e6b8e6b",
    total_wait_time: 422.0,
    doctor_delay_duration: 30,
    acknowledged: true,
    avg_wait_time: 45,
    escalation_level: "E1",
    room: "N/A",
    escalations: [
      {
        doctor_comment: "DELAYED_START",
        appointment_id: "0e73a5a8-f2e4-4249-9d62-564e8e1e4e40",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "dr. nandita grover",
        appointment_date: 1728757800,
        start_time: 1728802800,
        end_time: 1728804600,
        patient_name: "anil gupta",
        mobile: "9810021102",
        uhid: "skdd.153765",
        patient_checkedin_time: 1728801900,
        next_escalation_time: 1728803344,
        escalation_type: "E1",
        escalation_action: "DOCTOR_DELAY",
        escalation_start_time: 1728801544,
        escalation_end_time: null,
        patient_action_taken: "POSITIVE",
        created_at: 1728801544,
        wait_time: 168,
      },
      {
        doctor_comment: "DELAYED_START",
        appointment_id: "25de96c2-fc23-4774-9073-2aee84a24674",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "dr. nandita grover",
        appointment_date: 1728757800,
        start_time: 1728808800,
        end_time: 1728810000,
        patient_name: "anita kathpalia",
        mobile: "9873268759",
        uhid: "maxo.31363",
        patient_checkedin_time: 1728807900,
        next_escalation_time: 1728803344,
        escalation_type: "E1",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,
        patient_action_taken: "POSITIVE",
        created_at: 1728801544,
        wait_time: 68,
      },
      {
        doctor_comment: null,
        appointment_id: "295d4479-ed60-42a5-90e1-cbd93330a410",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "dr. nandita grover",
        appointment_date: 1728757800,
        start_time: 1728810000,
        end_time: 1728813600,
        patient_name: "ketaki goswami",
        mobile: "9810092013",
        uhid: "maxo.52001",
        patient_checkedin_time: 1728809100,
        next_escalation_time: 1728803344,
        escalation_type: "E1",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,
        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 48,
      },
      {
        doctor_comment: null,
        appointment_id: "cc551d23-7f79-4203-ab92-a4dc0e80df75",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "dr. nandita grover",
        appointment_date: 1728757800,
        start_time: 1728804600,
        end_time: 1728806400,
        patient_name: "vinita agarwal",
        mobile: "9910991177",
        uhid: "shpp.151095",
        patient_checkedin_time: null,
        next_escalation_time: 1728803344,
        escalation_type: "E1",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,

        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 138,
      },
    ],
  },
  {
    doctor_name: "DR. Sandeep Singh",
    doctor_id: "8e6b8e6b8e6d",
    total_wait_time: 160,
    doctor_delay_duration: 30,
    acknowledged: false,
    avg_wait_time: 20,
    escalation_level: "E2",
    room: "N/A",
    escalations: [
      {
        doctor_comment: null,
        appointment_id: "908adaab-9f3e-4837-a005-a4c387e6e290",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "DR. SHUBHAM MISHRA",
        appointment_date: 1728757800,
        start_time: 1728801000,
        end_time: 1728801900,
        patient_name: "wonshimwon r",
        mobile: "9863066339",
        uhid: "skdd.1067590",
        patient_checkedin_time: 1728800100,
        next_escalation_time: 1728803344,
        escalation_type: "E4",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,
        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 198,
      },
      {
        doctor_comment: null,
        appointment_id: "781b69a2-9c26-4e79-948b-cb0916098eb9",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "DR. SHUBHAM MISHRA",
        appointment_date: 1728757800,
        start_time: 1728801900,
        end_time: 1728802800,
        patient_name: "sanjay sejwal",
        mobile: "9999218880",
        uhid: "skdd.432310",
        patient_checkedin_time: 1728801000,
        next_escalation_time: 1728803344,
        escalation_type: "E3",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,

        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 183,
      },
      {
        doctor_comment: null,
        appointment_id: "781b69a2-9c26-4e79-948b-cb0916098eb10",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "DR. SHUBHAM MISHRA",
        appointment_date: 1728757800,
        start_time: 1728801900,
        end_time: 1728802800,
        patient_name: "sanjay sejwal",
        mobile: "9999218880",
        uhid: "skdd.432310",
        patient_checkedin_time: 1728801000,
        next_escalation_time: 1728803344,
        escalation_type: "E2",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,
        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 183,
      },
      {
        doctor_comment: null,
        appointment_id: "781b69a2-9c26-4e79-948b-cb0916098eb10",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "DR. Arjun Singh",
        appointment_date: 1728757800,
        start_time: 1728801900,
        end_time: 1728802800,
        patient_name: "sejal sejwal",
        mobile: "9999218880",
        uhid: "skdd.432311",
        patient_checkedin_time: 1728801000,
        next_escalation_time: 1728803344,
        escalation_type: "E1",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,
        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 183,
      },
    ],
  },
  {
    doctor_name: "DR. SHUBHAM MISHRA",
    doctor_id: "8e6b8e6b8e6c",
    doctor_delay_duration: 30,
    acknowledged: false,
    total_wait_time: 381.0,
    avg_wait_time: 20,
    escalation_level: "E3",
    room: "N/A",
    escalations: [
      {
        doctor_comment: null,
        appointment_id: "908adaab-9f3e-4837-a005-a4c387e6e290",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "DR. SHUBHAM MISHRA",
        appointment_date: 1728757800,
        start_time: 1728801000,
        end_time: 1728801900,
        patient_name: "wonshimwon r",
        mobile: "9863066339",
        uhid: "skdd.1067590",
        patient_checkedin_time: 1728800100,
        next_escalation_time: 1728803344,
        escalation_type: "E4",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,
        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 198,
      },
      {
        doctor_comment: null,
        appointment_id: "781b69a2-9c26-4e79-948b-cb0916098eb9",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "DR. SHUBHAM MISHRA",
        appointment_date: 1728757800,
        start_time: 1728801900,
        end_time: 1728802800,
        patient_name: "sanjay sejwal",
        mobile: "9999218880",
        uhid: "skdd.432310",
        patient_checkedin_time: 1728801000,
        next_escalation_time: 1728803344,
        escalation_type: "E3",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,

        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 183,
      },
      {
        doctor_comment: null,
        appointment_id: "781b69a2-9c26-4e79-948b-cb0916098eb10",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "DR. SHUBHAM MISHRA",
        appointment_date: 1728757800,
        start_time: 1728801900,
        end_time: 1728802800,
        patient_name: "sanjay sejwal",
        mobile: "9999218880",
        uhid: "skdd.432310",
        patient_checkedin_time: 1728801000,
        next_escalation_time: 1728803344,
        escalation_type: "E2",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,
        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 183,
      },
      {
        doctor_comment: null,
        appointment_id: "781b69a2-9c26-4e79-948b-cb0916098eb10",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "DR. Arjun Singh",
        appointment_date: 1728757800,
        start_time: 1728801900,
        end_time: 1728802800,
        patient_name: "sejal sejwal",
        mobile: "9999218880",
        uhid: "skdd.432311",
        patient_checkedin_time: 1728801000,
        next_escalation_time: 1728803344,
        escalation_type: "E1",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,
        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 183,
      },
    ],
  },
  {
    doctor_name: "Dr. Jatin Ahuja",
    doctor_id: "8e6b8e6b8e6c",
    total_wait_time: 594.0,
    avg_wait_time: 566,
    room: "N/A",
    escalation_level: "E4",
    doctor_delay_duration: 30,
    acknowledged: false,
    escalations: [
      {
        doctor_comment: null,
        appointment_id: "bd94e671-6662-4382-aa6f-cf23e29a638e",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "Dr. Jatin Ahuja",
        appointment_date: 1728757800,
        start_time: 1728801000,
        end_time: 1728802800,
        patient_name: "anupam gupta",
        mobile: "9717104718",
        uhid: "skms.482288",
        patient_checkedin_time: 1728800100,
        next_escalation_time: 1728803344,
        escalation_type: "E2",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,
        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 198,
      },
      {
        doctor_comment: null,
        appointment_id: "13bfc61f-4ccc-491b-acfa-785342a2516c",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "Dr. Jatin Ahuja",
        appointment_date: 1728757800,
        start_time: 1728802800,
        end_time: 1728804600,
        patient_name: "saumya gandhi",
        mobile: "9899504441",
        uhid: "skdd.1016408",
        patient_checkedin_time: 1728801900,
        next_escalation_time: 1728803344,
        escalation_type: "E4",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,
        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 168,
      },
      {
        doctor_comment: null,
        appointment_id: "7104b3b2-0866-42ec-83c8-52f5e3ec2a9b",
        clinic_id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
        clinic_name: "Max Hospital - Saket East",
        doctor_name: "Dr. Jatin Ahuja",
        appointment_date: 1728757800,
        start_time: 1728799200,
        end_time: 1728801000,
        patient_name: "anupam mahajan",
        mobile: "9205139149",
        uhid: "blkh.1236595",
        patient_checkedin_time: 1728818140,
        next_escalation_time: 1728803344,
        escalation_type: "E4",
        escalation_action: null,
        escalation_start_time: 1728801544,
        escalation_end_time: null,
        patient_action_taken: null,
        created_at: 1728801544,
        wait_time: 228,
      },
    ],
  },
];

export const ESCALATION_REPORT_DATA: Record<string, IReportData> = {
  "eba947d7-6faa-4236-ab61-d442cf54a528": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "7840d8c6-3509-48d6-8ea3-4bed103c6dcc": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "54406929-5705-4308-a843-0082e05a8623": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "09ff2a9b-2692-42b1-9a85-79e662e9160b": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "a7872cf4-25fa-4894-8309-73eb316154fb": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "95eb2416-3919-48a0-9a6c-75da506361c0": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "05478906-a974-4f78-84a2-38ce85c39206": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "bb2a3651-cb02-41aa-9fb3-1d11854973e0": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "cbb3329e-d89d-4bd2-b64d-e46e20fc018b": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "28f97deb-01bc-4d87-9234-48411be68ca8": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "17d6c290-0f12-4e27-b9e9-09ee2f2598ce": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "62a84dbe-2d9d-458a-9c3c-e3b9f149e92e": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "04b55f72-3aae-4c70-ad6e-3f2d2074c8ea": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "a622baa4-d8e9-45ad-88a7-b3c81a8f3c18": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "73a89ef4-e542-42c1-8189-392f9a1b10a0": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "3ac21495-2d90-4978-8537-51d7f698d8f3": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "62b7ae4f-fed7-43e9-bbfa-39eb8a749e8a": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "1a1975df-6a3a-4660-b66a-c1b64267ef98": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
  "88587db5-8539-4056-ae01-dd249c949a77": {
    e4_count: 2,
    e3_count: 4,
    e2_count: 5,
    e1_count: 1,
    ne_count: 4,
    total_escalations: 6,
  },
};
