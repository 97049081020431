const BASE_URL = 'https://analytics-beta.maxhealthcaredigital.com';

export async function fetchPatientCurrentStatus(
	id: string
): Promise<Record<'latest_status', string>> {
	const url = `${BASE_URL}/get-appointment-status?appointment_id=${id}`;

	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'ngrok-skip-browser-warning': 'true',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const data = await response.json();

		return data;
	} catch (error) {
		console.error('Error fetching data:', error);
		throw error;
	}
}
