import {
  ArrowForward,
  Person as DoctorIcon,
  Flag,
  TableViewOutlined as GridIcon,
  PrintTwoTone as PrintIcon,
  WarningSharp,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useEscalationViewEnabled from "../hooks";
import { Doctor, OPDChamberData } from "./models";
import { enabledHospitalsForEscalations } from "../escalation/data";

interface BackloggedDoctorsTableProps {
  sortedDoctors: Doctor[];
  opdData: OPDChamberData;
  handleDoctorClick: (doctor: Doctor) => void;
}

const BackloggedDoctorsTable: React.FC<BackloggedDoctorsTableProps> = ({
  sortedDoctors,
  opdData,
  handleDoctorClick,
}) => {
  const navigate = useNavigate();

  const [isEscalationEnabled] = useEscalationViewEnabled();

  const [showEscalation, setShowEscalation] = useState(isEscalationEnabled);

  const handlePrintBackloggedDoctors = () => {
    const printWindow = window.open("", "_blank");
    if (printWindow) {
      const content = `
                <html>
                    <head>
                        <title>Backlogged Doctors</title>
                        <style>
                            body { font-family: Arial, sans-serif; }
                            table { width: 100%; border-collapse: collapse; }
                            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                            th { background-color: #f2f2f2; }
                            .status-icon { width: 10px; height: 10px; border-radius: 50%; display: inline-block; margin-right: 5px; }
                            .status-in { background-color: green; }
                            .status-out { background-color: red; }
                            .status-none { background-color: gray; }
                        </style>
                    </head>
                    <body>
                        <h1>Backlogged Doctors</h1>
                        <table>
                            <thead>
                                <tr>
                                    <th>Doctor</th>
                                    <th>Room</th>
                                    <th>Status</th>
                                    <th>Wait Count</th>
                                    <th>Avg. Wait Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${sortedDoctors
                                  .map(
                                    (doc) => `
                                    <tr>
                                        <td>
                                            <span class="status-icon status-${getDoctorStatus(
                                              doc.doctorId
                                            )}"></span>
                                            ${doc.name}
                                        </td>
                                        <td>${getRoomNumber(doc.doctorId)}</td>
                                        <td>${getStatusText(doc)}</td>
                                        <td>${doc.waitCount}</td>
                                        <td>${doc.avgWaitTime}</td>
                                    </tr>
                                `
                                  )
                                  .join("")}
                            </tbody>
                        </table>
                    </body>
                </html>
            `;
      printWindow.document.write(content);
      printWindow.document.close();
      printWindow.print();
    }
  };

  const handleCSVExport = () => {
    const csvContent = [
      "Doctor,Room,Status,Wait Count,Avg. Wait Time",
      ...sortedDoctors.map((doc) =>
        [
          doc.name,
          getRoomNumber(doc.doctorId),
          getStatusText(doc).replace(/,/g, " | "),
          doc.waitCount,
          doc.avgWaitTime,
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "backlogged_doctors.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getDoctorStatus = (doctorId: string) => {
    if (opdData.inDoctors.some((d) => d.doctorId === doctorId)) return "in";
    if (opdData.assignments.some((d) => d.doctorId === doctorId)) return "out";
    return "none";
  };

  const getStatusText = (doctor: Doctor) => {
    const isSpill = doctor.doesHaveSpill;
    const conflictingDoctors = doctor.conflictsWith;

    let status = [];
    if (isSpill) status.push("Spillover");
    if (conflictingDoctors.length > 0)
      status.push(`Conflict with: ${conflictingDoctors.join(", ")}`);
    return status.join(", ") || "Normal";
  };

  const getStatusIcon = (doctor: Doctor) => {
    const isSpill = doctor.doesHaveSpill;
    const conflictingDoctors = doctor.conflictsWith;

    const icons = [];

    if (isSpill) {
      icons.push(
        <Tooltip key="spill" title="Spillover" arrow>
          <Flag className="text-yellow-500 mr-1" />
        </Tooltip>
      );
    }

    if (conflictingDoctors.length > 0) {
      console.log("COnflicting doctors", conflictingDoctors);
      const titleCaseNames = conflictingDoctors.map((name) =>
        name
          .split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ")
      );
      icons.push(
        <Tooltip
          key="conflict"
          title={`Conflict with: ${titleCaseNames.join(", ")}`}
          arrow
        >
          <WarningSharp className="text-red-500" />
        </Tooltip>
      );
    }

    return icons.length > 0 ? <div className="flex">{icons}</div> : null;
  };

  const getRoomNumber = (doctorId: string) => {
    const inDoctor = opdData.inDoctors.find((d) => d.doctorId === doctorId);
    if (inDoctor) return inDoctor.roomNumber;

    const assignment = opdData.assignments.find((a) => a.doctorId === doctorId);
    if (assignment) return assignment.roomNumber;

    return "N/A";
  };

  const handleViewEscalationClick = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const branchId =
      urlParams.get("branch_id") || "9757a411-668d-4261-bf93-bb97132cde6a";
    navigate(`/opd/escalation?branch_id=${branchId}`);
  }, [navigate]);

  useEffect(() => {
    const branchId = new URLSearchParams(window.location.search).get(
      "branch_id"
    );

    // show Escalations only if branch id is of Dwarka or Shalimar bagh
    setShowEscalation(
      isEscalationEnabled &&
        enabledHospitalsForEscalations.includes(String(branchId))
    );
  }, [isEscalationEnabled]);

  return (
    <div className="bg-gray-900 rounded-lg p-4 shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg sm:text-xl font-semibold flex items-center">
          <DoctorIcon className="mr-2" />
          Backlogged Doctors ({sortedDoctors.length})
        </h2>

        <div className="flex gap-2">
          {showEscalation && (
            <Button
              variant="contained"
              size="small"
              className="!font-bold"
              onClick={handleViewEscalationClick}
            >
              View Escalation
            </Button>
          )}

          {showEscalation && (
            <Button
              variant="contained"
              size="small"
              disabled
              color="secondary"
              className="!bg-gray-400 !text-white !font-bold !ml-2.5"
              endIcon={<ArrowForward />}
            >
              Escalate
            </Button>
          )}

          {showEscalation && (
            <span className="inline-block pr-1.5 self-center text-sm font-bold">
              In 30mins
            </span>
          )}

          <IconButton
            onClick={handlePrintBackloggedDoctors}
            className="text-white"
          >
            <PrintIcon color="primary" />
          </IconButton>

          <IconButton onClick={handleCSVExport} className="text-white">
            <GridIcon color="primary" />
          </IconButton>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-sm sm:text-base">
          <thead>
            <tr className="bg-gray-800">
              <th className="p-2 text-left">Doctor</th>
              <th className="p-2 text-left">Room</th>
              <th className="p-2 text-left">Status</th>
              <th className="p-2 text-right">Wait Count</th>
              <th className="p-2 text-right">Avg. Wait Time</th>
            </tr>
          </thead>
          <tbody>
            {sortedDoctors.map((doc, index) => (
              <tr
                key={doc.doctorId}
                className={`${
                  index % 2 === 0 ? "bg-gray-800" : "bg-gray-700"
                } hover:bg-gray-600 cursor-pointer`}
                onClick={() => handleDoctorClick(doc)}
              >
                <td className="p-2 flex items-center">
                  <span
                    className={`inline-block w-2 h-2 rounded-full mr-2 ${
                      getDoctorStatus(doc.doctorId) === "in"
                        ? "bg-green-500"
                        : getDoctorStatus(doc.doctorId) === "out"
                        ? "bg-red-500"
                        : "bg-gray-500"
                    }`}
                  ></span>
                  <span>{doc.name}</span>
                </td>
                <td className="p-2">{getRoomNumber(doc.doctorId)}</td>
                <td className="p-2">{getStatusIcon(doc)}</td>
                <td className="p-2 text-right">{doc.waitCount}</td>
                <td className="p-2 text-right">{doc.avgWaitTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BackloggedDoctorsTable;
