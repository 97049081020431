import { TEscalationAction } from '../escalation/fetchEscalations';

export const DOCTOR_STATUS: Array<{ label: string; value: TEscalationAction }> =
	[
		{ label: 'Doctor Delayed', value: 'DOCTOR_DELAY' },
		{ label: 'Doctor On Leave', value: 'DOCTOR_LEAVE' },
		{ label: 'Manual Prescription', value: 'MANUAL_PRESCRIPTION' },
		{
			label: 'Doctor/Coordinator Not Reachable',
			value: 'DOCTOR_NOT_REACHABLE',
		},
	];
